import { store } from '@/store/store';
import { Licencia } from '@/shared/dtos/Licencia';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';
import { licencia } from '@/shared/dtos/admin/licencia';

@Module({
    namespaced: true,
    name: 'LicenciaModule',
    store,
    dynamic: true
})
class LicenciaModule extends VuexModule {
    public Licencia: Licencia = new Licencia();

    @Action({ commit: 'onGetLicencia' })
    public async getLicencias() {
        return await ssmHttpService.get(API.Licencia + '/nutricionista');
    }

    @Action
    public async modificarlicencia(licen: licencia) {
        await ssmHttpService.put(API.Licencia + '/' + licen.id, licen);
    }

    @Mutation
    public onGetLicencia(res: Licencia) {
        this.Licencia = res;
    }

}
export default getModule(LicenciaModule);