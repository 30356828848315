import Header from '@/components/header/Header.vue';
import { MenuOptions } from '@/components/Menu/menu-options';
import MenuRoot from '@/components/Menu/menu-root.vue';
import { Permission } from '@/shared/permission';
import { ssmPermissionService } from '@/shared/services/PermissionService';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import CSSModule from '@/store/modules/css-module';
import "material-design-icons/iconfont/material-icons.css";
//import 'devextreme/dist/css/dx.material.orange.light.compact.css';
import '@/assets/css/dx.material.my-medical-diet-theme.css';
import 'devextreme/dist/css/dx.common.css';
import '@/assets/scss/main.scss';
import 'vuesax/dist/vuesax.css'; // Vuesax
import notificationModule from '@/components/header/Notification/notification-module';
@Component({
  components: {
    MenuRoot,
    Header
  },
})
export default class MainLayout extends Vue {
  public fullMenuOptions = MenuOptions;
  public appTitle = process.env.VUE_APP_TITLE;
  public route: any;
  public refreshIntervalId = null;
  public varCounter = 0;
  public constructor() {
    super();
    this.bodystyle();
    Vue.prototype.$heightsection = this.$vuetify.breakpoint.height - 110;
  }
  public created() {
    this.fakePermisssions();
    notificationModule.getAvisos();
    /*if (ssmTokenService.hasToken() && medicalUserService.hasuser()) {
      ssmSignalRService.setWebChannelHubBase((process.env.VUE_APP_ROOT_CHANNELHUB as string));
    }

    // si se desconecta del servidor automaticamente que se vuelva a conectar. cada 3s
    VueInstanceService.vueinstance.$on(
      "change-web-socket",
      (conected: boolean) => {
        if (!conected) {
          //@ts-ignore
          this.refreshIntervalId = setInterval(() => {
            if (this.varCounter <= 30) {
              this.varCounter++;
              if (ssmTokenService.hasToken() && medicalUserService.hasuser()) {
                ssmSignalRService.setWebChannelHubBase((process.env.VUE_APP_ROOT_CHANNELHUB as string));
              }
            } else {
              //@ts-ignore
              clearInterval(this.refreshIntervalId);
            }
          }, 3000);
        }
      }
    );*/
  }
  public get animation_type() {
    this.route = this.$route;
    let animations = ['fade', 'slide-fade', 'bounce'];
    return animations[Math.floor(Math.random() * 3)];
  }
  public async bodystyle() {
    if ((process.env.VUE_APP_PRODUCTION as string) === 'true') {
      if (this.$vuetify.breakpoint.smAndDown) {
        document.body.style.paddingRight = "0px";
      } else {
        document.body.style.overflowX = "visible";
        document.body.style.paddingRight = "0px";
      }
    }
    if (!CSSModule.cssimportados) {
      var files: string[] = [];
      // files.push('https://mymedicaldiet.es/wp-content/uploads/19.2.7.css.dx.common.css');
      // files.push('https://mymedicaldiet.es/wp-content/uploads/19.2.7.css.dx.material.orange.light.compact.css');
      //files.push('https://kit-pro.fontawesome.com/releases/v5.13.0/css/pro.min.css');
      files.push('https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css');
      for (let i = 0; i < files.length; i++) {
        /* const element = files[i];
         var styles = "@import url('" + files[i] + "');";*/
        var newSS = document.createElement('link');
        newSS.rel = 'stylesheet';
        newSS.href = files[i];//'data:text/css,' + escape(styles);
        document.getElementsByTagName("head")[0].appendChild(newSS);
      }
      const esMessages = await import("devextreme/localization/messages/es.json");
      const { locale, loadMessages } = await import("devextreme/localization");
      //import esMessages from "devextreme/localization/messages/es.json";
      //import { locale, loadMessages} from "devextreme/localization";

      loadMessages(esMessages);

      locale("es");
      // Styles: SCSS

      CSSModule.css_importados();
    }
  }

  private fakePermisssions() {
    // Asignamos unos permisos iniciales para pruebas,
    // en un entorno real sería el back quien indicase los permisos del usuario
    const permissions: string[] = [
      Permission.readUsuarios,
      Permission.readRoles,
      Permission.readPartes
    ];
    ssmPermissionService.setPermissions(permissions);
  }

}
